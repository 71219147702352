.material {
    
    //  background-color: red;
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active  {
        border:none;
    border-bottom: 2px solid #007bff;
    
    border-radius: 0;
}
.nav-tabs .nav-link{
    border: 0px;
}
}