.f1-accordian {
	box-sizing: border-box;
	text-align: left;
}


.Collapsible{
	position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

#openOnTop .Collapsible{
    flex-direction: column-reverse;
}

.Collapsible__trigger {
    border-radius: 0;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Collapsible__contentOuter {    
	height: 0px;
    transition: height 400ms linear 0s;
    overflow: hidden;
}
.Collapsible__contentInner{
	padding: 0 5px;
}
